.btn {
  width: 100%;
  margin: 10px auto;
  background-color: #516BEB !important;
  color: white !important;
  padding: 1rem 0;
  border-radius: 5px;
}

input {
  box-sizing: border-box;
  border-radius: 2px;
  padding: 10px;
  width: 100%;
  margin: auto
}

.error {
  color: red;
  font-size: 14px;
  margin: 0;
}

a {
  text-decoration: none;
  color: black;
}

a:hover {
  color: black;
}

a:focus {
  border: 0;
}

.header {
  padding: 30px 20px;
  display: flex;

  img {
    width: 150px;
  }
}