// Styling for Login Page
.login-form {
  padding: 60px 40px !important;
  margin: auto;

  .btn {
    background-color: #516BEB;
    font-weight: 600;
  }

  span {
    color: #516BEB;
  }

  input {
    border: 1.30729px solid #516BEB;
    border-radius: 3.31643px;


  }

  .invalid {
    border: 1.30729px solid red;
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;

    img {
      width: 70%;
    }

  }
}

.loginImage {
  @media (max-width: 991px) {
    display: none;
  }
}

//Styling for Sign Up Page
.register-form {
  margin: auto;
  padding: 60px 0;

  @media (max-width: 576px) {
    padding: 60px 32px;
  }

  .btn {
    background-color: #516BEB;
    font-weight: 600;
  }

  span {
    color: #516BEB;
  }

  input {
    border: 1.30729px solid #516BEB;
    border-radius: 3.31643px;


  }

  .invalid {
    border: 1.30729px solid red;
  }

}

//Styling for Confirm Sign Up Page
.confirm-page {
  @media (max-width: 991px) {
    flex-direction: column-reverse;
  }
}

.confirm-form {
  padding: 60px 0 0 20px;
  margin: auto;

  @media (max-width: 616px) {
    padding: 40px 32px;
  }

  .btn {
    background-color: #516BEB;
    font-weight: 600;
    padding: 12px 0;
  }

  span {
    color: #516BEB;
  }

  input {
    border: 1.30729px solid #516BEB;
    border-radius: 3.31643px;

    &:focus {
      border: 1.30729px solid #516BEB;
    }
  }


  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;

    img {
      width: 70%;
    }

  }
}

.loginImage {
  @media (max-width: 991px) {
    display: none;
  }
}