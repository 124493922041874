@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
nav {
  display: initial;
}

/* desktop screen styling starts here */
.mobile-navbar {
  display: none;
}

.desktop-navbar {
  border-bottom: 1px solid var(--Secondary, #ddf2ff);
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  padding: 0 4rem;
}

.icon-logo {
  width: 126px;
}

.desktop-navbar-img {
  width: 100%;
}

.desktop-nav-links {
  display: flex;
  align-items: center;
}

.desktop-nav-list {
  color: #667085;
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  margin-left: 40px;
  line-height: 22px;
  text-decoration: none;
}

.login-link {
  background-color: #6499e9;
  border-radius: 5px;
  color: #fff;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
  margin-left: 40px;
  padding: 8px 18px;
}

.login-link:hover {
  background-color: #538fea;
  color: #fff;
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) and (max-width: 960px) {
  .desktop-navbar {
    display: none;
  }

  nav {
    padding-bottom: 55px;
  }

  .mobile-navbar {
    width: 100%;
    display: block;
    position: sticky;
    top: 0;
    z-index: 9999;
  }

  .mobile-navbar-relative {
    width: 100%;
    position: sticky;
    z-index: 99999;
  }

  .mobile-nav-body {
    background-color: #fff;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.08);
    width: 100%;
    max-width: 100%;
    padding: 15px;
    border-bottom: 3px solid #ffffff;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mobile-nav-body section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mobile-nav-body section img {
    width: 30px;
    height: 17px;
  }

  .nav-search {
    fill: #6499e9;
    font-size: 26px;
  }

  /* hamburger menu styling */
  .mobile-hamburger-btn {
    width: 28px;
    height: 4px;
    background: #6499e9;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
  }

  .mobile-hamburger-btn::after,
  .mobile-hamburger-btn::before {
    content: ' ';
    position: absolute;
    width: 28px;
    height: 4px;
    background: #6499e9;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
  }

  .mobile-hamburger-btn::before {
    transform: translateY(-10px);
  }

  .mobile-hamburger-btn::after {
    transform: translateY(10px);
  }

  /* mobile-hamburger animation */
  .mobile-hamburger-btn.close {
    transform: translateX(-50px);
    background: transparent;
  }

  .mobile-hamburger-btn.close::before {
    transform: rotate(45deg) translate(35px, -35px);
  }

  .mobile-hamburger-btn.close::after {
    transform: rotate(-45deg) translate(35px, 35px);
  }

  /* nav links style on mobile view */
  .mobile-nav-links {
    position: fixed;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: #d4dbff;
    min-height: 70vh;
    width: 40%;
    z-index: 1;
    clip-path: inset(0% 0% 100% 0%);
    -webkit-clip-path: inset(0% 0% 100% 0%);
    transition: all 0.5s ease-out;
    pointer-events: none;
    padding: 120px 30px 20px;
  }

  .mobile-nav-list {
    color: #002797;
    list-style: none;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
    text-decoration: none;
  }

  .mobile-nav-list:nth-last-child(1) {
    background-color: #002797;
    color: #fff;
    padding: 10px 0 10px 15px;
    border-radius: 5px;
    width: 10vw;
  }

  .mobile-nav-list:hover {
    color: #f9b326;
    text-decoration: underline;
  }

  .mobile-nav-links.open {
    clip-path: inset(0% 0% 0% 0%);
    -webkit-clip-path: inset(0% 0% 0% 0%);
    pointer-events: all;
  }
}

/* ------------styling for mobile view--------------- */
@media screen and (max-width: 768px) {
  nav {
    padding-bottom: 55px;
  }

  .desktop-navbar {
    display: none;
  }

  .desktop-navbar-img {
    display: none;
  }

  .mobile-navbar {
    display: block;
    position: sticky;
    top: 0;
    z-index: 9999;
  }

  .mobile-navbar-relative {
    position: sticky;
    top: 0;
    z-index: 99999;
    width: 100%;
  }

  .mobile-nav-body {
    background-color: #fff;
    border-bottom: 1px solid #ddf2ff;
    padding: 15px;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mobile-nav-body .icon-logo img {
    height: 20px;
    margin-right: 5px;
  }

  .mobile-nav-button {
    border-radius: 8px;
    border: 1px solid #6499e9;
    background: #6499e9;
    color: #fff;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;
    padding: 10px 18px;
  }

  .mobile-nav-button:hover {
    color: #fff;
  }

  /* hamburger menu styling */
  .mobile-hamburger-btn {
    width: 26px;
    height: 3px;
    background: #6499e9;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
    margin-left: 20px;
  }

  .mobile-hamburger-btn::after,
  .mobile-hamburger-btn::before {
    content: ' ';
    position: absolute;
    width: 26px;
    height: 3px;
    background: #6499e9;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
  }

  .mobile-hamburger-btn::before {
    transform: translateY(-10px);
  }

  .mobile-hamburger-btn::after {
    transform: translateY(10px);
  }

  /* mobile-hamburger animation */
  .mobile-hamburger-btn.close {
    transform: translateX(-50px);
    background: transparent;
  }

  .mobile-hamburger-btn.close::before {
    transform: rotate(45deg) translate(35px, -35px);
  }

  .mobile-hamburger-btn.close::after {
    transform: rotate(-45deg) translate(35px, 35px);
  }

  /* nav links style on mobile view */
  .mobile-nav-links {
    position: fixed;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: #d4dbff;
    min-height: 40vh;
    width: 100%;
    z-index: 1;
    clip-path: inset(0% 0% 100% 0%);
    -webkit-clip-path: inset(0% 0% 100% 0%);
    transition: all 0.5s ease-out;
    pointer-events: none;
    padding: 20px;
  }

  .mobile-nav-list {
    color: #667085;
    list-style: none;
    font-size: 14px;
    font-family: 'Inter';
    font-weight: 400;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
    text-decoration: none;
  }

  .mobile-nav-list:hover {
    text-decoration: underline;
  }

  .mobile-nav-links.open {
    clip-path: inset(0% 0% 0% 0%);
    -webkit-clip-path: inset(0% 0% 0% 0%);
    pointer-events: all;
  }
}
