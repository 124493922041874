@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600&family=Poppins:wght@600&family=Rubik:wght@300;400&display=swap');

.dashboard-home {
  padding: 1rem 2rem;
  font-family: 'Poppins', sans-serif;
}

.allcourses {
  padding-bottom: 50px;
}

/* styling for the courses */
.allcourses .allcourses-content {
  cursor: pointer;
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 40px;
  flex-wrap: wrap;
}

.allcourses .allcourses-content .course {
  background: #ffffff;
  border: 2px solid rgba(81, 107, 235, 0.2);
  border-radius: 10px;
  padding: 20px;
}

.allcourses .allcourses-content .course:hover {
  box-shadow: 0px 3.84088px 15.7476px rgba(81, 107, 235, 0.15);
}

.allcourses .subscribe-btn {
  color: #516beb;
}

.allcourses .allcourses-content .course .course-thumbnail {
  width: 100%;
  height: 27vh;
  position: relative;
}

.allcourses
  .allcourses-content
  .course
  .course-thumbnail:hover
  .play-icon-body {
  display: flex;
  animation: zoom-in 250ms ease-in-out alternate;
}

@keyframes zoom-in {
  0% {
    transform: scale(0.6, 0.6);
  }
  50% {
    transform: scale(0.8, 0.8);
  }
  100% {
    transform: scale(1, 1);
  }
}

.react-player {
  position: absolute;
  padding: 5px;
  border: 5px solid #516beb;
}

.play-icon-body {
  display: none;
  position: absolute;
  background: #516beb;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  top: 43%;
  left: 43%;
  padding-left: 5px;
  cursor: pointer;
}

.play-icon {
  fill: #fff;
  font-size: 50px;
}

.allcourses .allcourses-content .course .course-thumbnail img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.allcourses .allcourses-content .course .progress {
  color: #f48c06;
  font-size: 0.9rem;
  font-weight: bold;
  background-color: transparent;
  margin-bottom: 0;
}

.allcourses .allcourses-content .course .progress-completed {
  color: #41be90;
  font-size: 0.9rem;
  font-weight: bold;
  background-color: transparent;
  margin-bottom: 0;
}

.allcourses .allcourses-content .course p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #516beb;
  margin: auto;
  margin-top: 10px;
}

.allcourses .allcourses-content .course p:hover {
  color: #1a3df1;
}

/* Responsive styling for the dashboard home */
@media screen and (max-width: 450px) {
  .dashboard-home {
    padding: 1.5rem;
  }

  .allcourses .allcourses-content {
    grid-template-columns: 1fr;
    align-items: center;
    width: 100%;
  }

  .allcourses .allcourses-content .course {
    width: 100%;
    margin-bottom: 1rem;
  }
}
