.modal-background {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  width: 100vw;
  height: 100vh;
}

.modal-container {
  background: #fff;
  border: 1px solid #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
  height: 25%;
  width: 30%;
}

.modal-container h2 {
  color: #101828;
  font-family: Inter;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
}

.modal-container .modal-buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  align-items: center;
  width: 100%;
}

.modal-container .modal-buttons button {
  background-color: transparent;
  color: #6499e9;
  border: 1px solid #6499e9;
  border-radius: 8px;
  padding: 10px 0;
}

.modal-container .modal-buttons button:hover {
  background-color: #6499e9;
  color: #fff;
}

/* ------------styling for mobile view--------------- */
@media screen and (max-width: 768px) {
  .modal-container {
    height: 20%;
    width: 90%;
  }
}
