@import url('https://fonts.googleapis.com/css2?family=Lora&family=Oleo+Script:wght@400;700&family=Poppins:wght@600&family=Rubik:wght@300;400&display=swap');

:root {
  --blue: #6499e9;
  --darkGrey: #272727;
  --border: 3px solid rgba(81, 107, 235, 0.5);
}

.certificate-home {
  color: var(--darkGrey);
  background-image: linear-gradient(
    350deg,
    hsl(0deg 0% 100%) 13%,
    hsl(204deg 100% 99%) 39%,
    hsl(203deg 100% 99%) 46%,
    hsl(203deg 100% 98%) 49%,
    hsl(203deg 100% 98%) 50%,
    hsl(203deg 100% 97%) 50%,
    hsl(203deg 100% 97%) 50%,
    hsl(203deg 100% 96%) 50%,
    hsl(203deg 100% 96%) 50%,
    hsl(203deg 100% 95%) 50%,
    hsl(203deg 100% 95%) 51%,
    hsl(203deg 100% 94%) 54%,
    hsl(203deg 100% 94%) 61%,
    hsl(203deg 100% 93%) 87%
  );
  padding-bottom: 50px;
}

.certificate-header {
  text-align: center;
  padding: 20px 0;
}

.certificate-header h2 {
  background-color: #bfe6fe;
  color: #6499e9;
  font-weight: 500;
  font-family: Inter;
  font-size: 14px;
  padding: 4px 12px;
  border-radius: 16px;
  margin: 0 auto;
  margin-bottom: 20px;
  width: fit-content;
}

.certificate-header h1,
.certificate section h3 {
  color: #667085;
  font-weight: 600;
  font-family: Inter;
  font-size: 48px;
  margin-bottom: 5px;
}

.certificate-header p {
  color: #6499e9;
  font-weight: 400;
  font-family: Inter;
  font-size: 20px;
  margin: 0;
}

.certificate {
  background-color: #fff;
  border: 1px solid;
  position: relative;
  height: 70vh;
  width: 40%;
  margin: auto;
  margin-bottom: 30px;
  padding: 20px;
  font-family: Inter;
  text-align: center;
}

.certificate-home .certificate .header {
  display: flex;
  justify-content: center;
  color: #436898;
  align-items: baseline;
  margin-bottom: 20px;
  padding: 0;
}

.certificate-home .certificate .header img {
  width: 130px;
}

.certificate-home .certificate .certificate-body h3 {
  font-size: 32px;
  font-family: 'Inter', sans-serif;
  margin-bottom: 40px;
  color: #436898;
}

.certificate-home .certificate .certificate-body h5,
.certificate-home .certificate .certificate-body p {
  text-transform: uppercase;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  margin-bottom: 30px;
}

.certificate-home .certificate .certificate-body p {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}

.certificate-home .certificate .certificate-body h4,
.certificate-home .certificate .certificate-body p span {
  color: #6499e9;
  font-family: 'Oleo Script', cursive;
  font-weight: 400;
  font-size: 20px;
}

.certificate-home .certificate .certificate-body p span {
  font-weight: 700;
  margin: 0 20px;
  width: fit-content;
  line-height: 40px;
}

.certificate .certificate-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #436898;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 13vh;
  padding: 0 30px;
}

.certificate .certificate-footer div p {
  font-family: 'Rubik', sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
}

.certificate .certificate-footer div hr {
  border: 1px solid #fff;
  background: #fff;
  margin-top: 5px;
  margin-bottom: 4px;
}

.certificate .certificate-footer div img {
  width: 40%;
}

.certificate-download-btn {
  display: flex;
  justify-content: center;
}

.certificate-download-btn button {
  border: 2px solid #6499e9;
  border-radius: 8px;
  background-color: var(--blue);
  color: #fff;
  display: flex;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 20px;
}

.certificate-download-btn button #btn-icon {
  font-size: 18px;
  margin-left: 5px;
}

@media screen and (max-width: 768px) {
  .certificate-home {
    padding: 0 20px;
    padding-bottom: 40px;
  }

  .certificate-header h1,
  .certificate section h3 {
    font-size: 24px;
  }

  .certificate-header p {
    font-size: 14px;
  }

  .certificate {
    width: 100%;
    height: 40vh;
  }

  .certificate-home .certificate .certificate-body h3 {
    font-size: 20px;
    margin-bottom: 30px;
  }

  .certificate-home .certificate .certificate-body h5 {
    margin-bottom: 20px;
  }

  .certificate-home .certificate .certificate-body h4,
  .certificate-home .certificate .certificate-body p span {
    font-size: 16px;
  }

  .certificate-home .certificate .certificate-body p {
    font-size: 14px;
  }

  .certificate-home .certificate .certificate-body p span {
    line-height: 0;
  }

  .certificate .certificate-footer {
    padding: 0 20px;
    height: 10vh;
  }

  .certificate .certificate-footer div {
    width: 40%;
  }

  .certificate .certificate-footer div p {
    font-size: 14px;
  }

  .certificate .certificate-footer div img {
    width: 40%;
  }
}
