@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600&family=Poppins:wght@600&family=Rubik:wght@300;400&display=swap');

.user-profile {
  font-family: 'Poppins', sans-serif;
  padding: 1rem 2rem;
  padding-bottom: 100px;
}

/* profile header div */
.profile-header {
  padding-top: 30px;
}

.profile-header,
.profile-header div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

.profile-header h1 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 119.5%;
  color: #6499e9;
}

.profile-header div {
  border: 2px solid #6499e9;
  border-radius: 6px;
  padding: 2px 0 2px 1px;
}

.profile-header div input {
  border: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #6499e9;
}

.profile-header div input::placeholder {
  color: #6499e9;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}

.profile-header div #font {
  color: #6499e9;
  font-size: 22px;
  margin: 0 5px;
  margin-right: 7px;
}

/* styling for the courses */
.completed-courses {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 40px;
  cursor: pointer;
}

.completed-courses .course {
  background: #ffffff;
  border: 2px solid rgba(81, 107, 235, 0.2);
  border-radius: 10px;
  padding: 20px;
}

.completed-courses .course:hover {
  box-shadow: 0px 3.84088px 15.7476px rgba(81, 107, 235, 0.15);
}

.completed-courses .course .course-thumbnail {
  width: 100% !important;
  height: 27vh;
}

.completed-courses .course .course-thumbnail img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.completed-courses .course p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #516beb;
  margin: auto;
  margin-top: 10px;
}

.completed-courses .course p:hover {
  color: #1a3df1;
}

/* Responsive styling for the profile */
@media screen and (max-width: 768px) {
  .user-profile {
    padding: 1.5rem;
  }

  .profile .profile-header {
    flex-wrap: wrap;
    width: 100%;
  }

  .profile-header h1 {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 20px;
  }

  .profile-header div {
    width: 100%;
  }

  .profile-header div input::placeholder {
    font-size: 14px;
  }

  .profile .profile-details {
    flex-direction: column;
    width: 100%;
  }

  .profile .profile-details div {
    width: 100%;
    flex-direction: column-reverse;
  }

  .profile .profile-details div p {
    margin: 0;
  }

  .profile .profile-details div p:nth-child(2) {
    margin-top: 20px;
  }

  .completed-courses {
    grid-template-columns: 1fr;
    align-items: center;
    width: 100%;
  }

  .completed-courses .course .course-thumbnail {
    width: 100% !important;
  }
}
