.innovation-container {
  padding: 2rem 0;
  width: 30%;
  margin: 0 auto;
}

.header-tag {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  color: #6499e9;
}

.innovation-container {
  margin-bottom: 30px;
}

.innovation-container label,
.innovation-container p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  color: #000000;
  margin-bottom: 6px;
  margin: 0;
}

.innovation-container label {
  margin: 30px 0 5px 0;
}

.innovation-container input {
  background: transparent;
  border: 2px solid #6499e9;
  border-radius: 5px;
  font-size: 1.2rem;
  height: 4vh;
  width: 100%;
}

.innovation-container .box-check {
  margin-top: 30px;
}

.box-check-button {
  display: flex;
}

.box-check-button p {
  border-radius: 5px;
  border: 2px solid #6499e9;
  background: transparent;
  color: #6499e9;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
  margin: 5px 20px 0 0;
  padding: 5px 20px;
  text-transform: uppercase;
}

.active-button {
  background-color: #6499e9 !important;
  color: #fff !important;
}

.innovation-container textarea {
  border-radius: 5px;
  border: 2px solid #6499e9;
  background: transparent;
  font-size: 1.2rem;
  padding: 10px;
  width: 100%;
  height: 40vh;
}

.innovation-container button {
  background: #6499e9;
  border: none;
  border-radius: 8px;
  color: #fff;
  font-weight: bold;
  font-size: 1.4rem;
  margin: 0 auto;
  padding: 5px 0;
  width: 50%;
}

.innovation-container .error {
  color: rgb(250, 70, 70);
  font-size: 1rem;
}

@media screen and (max-width: 768px) {
  .innovation-container {
    padding: 1.5rem 20px;
    width: 100%;
  }

  .header-tag {
    font-size: 1rem;
    margin-bottom: 30px;
  }

  br {
    display: none;
  }

  .innovation-container label,
  .innovation-container p {
    font-size: 0.9rem;
    margin-bottom: 5px;
  }

  .innovation-container input {
    font-size: 1rem;
    height: 4vh;
    width: 100%;
  }

  .innovation-container .mobileInput {
    height: 4vh !important;
  }

  .box-check-button p {
    font-size: 1.2rem;
    margin-bottom: 20px;
    margin-right: 15px;
  }

  .innovation-container textarea {
    font-size: 0.9rem;
    padding: 5px;
    width: 100%;
    height: 20vh;
  }

  .innovation-container button {
    width: 100%;
    font-size: 1.2rem;
    margin-top: 20px;
  }

  .innovation-container .error {
    font-size: 0.8rem;
  }
}
