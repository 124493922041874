@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600&family=Poppins:wght@600&family=Rubik:wght@300;400&display=swap');

.landing-parent {
	background: #2f327d;
	color: #fff;
	padding: 3rem 2rem;
	min-height: 100vh;
	width: 100%;
}

.score-board {
	padding: 3rem 9rem;
}

.score-header {
	font-family: 'Inter', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 2rem;
	margin: 0;
	margin-bottom: 5px;
}

.score-sub-header {
	font-family: 'Inter', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 1.6rem;
}

table {
	width: 70%;
	margin-top: 40px;
	border-collapse: collapse;
}

table td {
	padding: 10px 20px;
	border: 1px solid;
}

table thead td {
	font-size: 2rem;
	font-weight: bold;
}

table tbody td {
	font-size: 1.6rem;
}
