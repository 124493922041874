@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h4,
label,
p,
input,
button {
  display: block;
  font-family: 'Inter', sans-serif;
}

:root {
  --black: #101828;
  --gray: #667085;
  --darkGray: #344054;
  --blue-link: #6499e9;
}

.login-parent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.signup-parent div {
  height: fit-content !important;
}

.login-parent div {
  margin: 0;
  height: 90vh;
  max-height: fit-content;
}

.login-form {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.login-form form {
  width: 60%;
}

.login-form .success-message {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 100%;
}

.login-form .success-message a {
  color: var(--darkGray);
  font-size: 34px;
  font-weight: 500;
}

.login-form h1,
.login-form .success-message h1 {
  font-size: 38px;
  font-weight: 600;
  color: var(--black);
  margin-bottom: 10px;
}

.login-form p {
  color: var(--gray);
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 40px;
}

.login-form label,
.login-form .forgot-password a {
  color: var(--darkGray);
  font-size: 14px;
  font-weight: 500;
}

.login-form input {
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  color: var(--darkGray);
  padding: 10px 14px;
  font-size: 14px;
  font-weight: 400;
  margin: 5px 0 30px 0;
}

.login-form input::placeholder {
  color: var(--gray);
  font-size: 14px;
  font-weight: 400;
}

.login-form .forgot-password,
.login-form .forgot-password p {
  color: var(--darkGray);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
}

.login-form .forgot-password input {
  border: 1px solid #d0d5dd;
  cursor: pointer;
  margin: 0;
  margin-right: 5px;
  outline: #fff;
  height: 16px;
  width: 16px;
}

.login-form .password-validation {
  font-size: 12px;
  margin-top: -25px;
}

.login-form .error-message {
  color: red;
  margin-top: -25px;
  margin-bottom: 0;
  text-align: right;
  font-size: 14px;
}

.login-form .forgot-password a,
.login-form .no_account a,
.login-form .success-message a {
  color: var(--blue-link);
  text-decoration: none;
}

.login-form button {
  background-color: var(--blue-link);
  border: 1px solid var(--blue-link);
  border-radius: 8px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  margin-top: 30px;
  padding: 10px 0;
  width: 100%;
}

.login-form .no_account {
  text-align: center;
  margin-top: 20px;
}

.login-form h4 {
  color: var(--gray);
  font-size: 14px;
  position: absolute;
  bottom: 0;
}

.login-image-parent {
  width: 80%;
  height: 100%;
  justify-self: flex-end;
}

.login-image-parent img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* ------------styling for mobile view--------------- */
@media screen and (max-width: 600px) {
  .login-parent {
    grid-template-columns: 1fr;
  }

  .login-form form,
  .login-form .success-message {
    width: 100%;
  }

  .login-form .error-message {
    margin-bottom: 10px;
  }

  .login-image-parent {
    width: 100%;
    display: none;
  }
}
