.dashboard-div {
  position: relative;
}

.dashboard {
  display: flex;
  position: relative;
}

.sidebar {
  flex: 1;
  position: sticky;
  top: 0;
  bottom: 0;
  left: 0;
  border: 2px solid #dddddd;
  border-radius: 0 13px 13px 0;
  height: 100vh;
  padding: 1rem;
}

.sidebar-mobile {
  display: none;
  flex: 1;
  position: sticky;
  top: 0;
  bottom: 0;
  border: 2px solid #dddddd;
  border-radius: 13px;
  height: 100vh;
  padding: 1rem;
}

.sidebar-content {
  flex: 4;
}

.collapsed-content {
  flex: 25;
}

.mobile-view {
  display: none;
}

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  width: 100%;
  height: 100%;
}

.collapsible-btn {
  position: absolute;
  background: #516beb;
  width: 2rem;
  height: 2rem;
  border: none;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 5%;
  right: -18px;
  cursor: pointer;
}

.collapsed {
  top: 3%;
}

/* Responsive styling for the dashboard */
@media screen and (max-width: 767px) {
  .sidebar {
    display: none;
    position: absolute;
    left: -100vw;
    top: 0;
    z-index: 5;
    background: #516beb;
    width: 70%;
    border: none;
    border-top-left-radius: 0;
  }

  .sidebar-mobile {
    display: block;
    position: absolute;
    left: -100vw;
    top: 0;
    z-index: 5;
    background: #516beb;
    width: 70%;
    border: none;
    border-top-left-radius: 0;
  }

  .mobile-view {
    display: block;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
    width: 100%;
    height: 3rem;
    padding: 0 4%;
  }

  .mobile-view img {
    width: 50%;
    object-fit: contain;
  }

  .mobile-view .hamburger {
    font-size: 2.5rem;
    color: #516beb;
    z-index: 4;
  }

  .collapsible-btn {
    display: none;
  }

  .allcourses .profile-header {
    display: block;
  }

  /* the styling that will be added to the sidebar when the hamburger*/
  /* menu is clicked. */
  .open {
    position: absolute;
    top: 0;
    left: 0;
    transition: all ease 0.5s;
  }
}

@media screen and (min-width: 451px) and (max-width: 767px) {
  .sidebar {
    position: absolute;
    left: -100vw;
    top: 0;
    z-index: 5;
    background: #516beb;
    width: 70%;
    border: none;
    border-top-left-radius: 0;
  }

  .mobile-view {
    display: block;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
    width: 100%;
    padding: 0 4%;
    height: 2rem;
  }

  .mobile-view img {
    width: 40%;
    object-fit: contain;
  }

  .mobile-view .hamburger {
    font-size: 2rem;
    color: #516beb;
    z-index: 4;
  }

  /* the styling that will be added to the sidebar when the hamburger*/
  /* menu is clicked. */
  .open {
    position: absolute;
    top: 0;
    left: 0;
    transition: all ease 0.5s;
  }
}

.loader-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 800;
  width: 100vw;
  height: 100vh;
}
