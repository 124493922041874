@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Poppins:wght@600&family=Rubik:wght@300;400&display=swap');

.settings h5,
.settings label,
.settings input {
  display: block;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
}

.settings {
  padding: 2rem 0;
}

.settings .profile-parent {
  padding: 0 3rem;
}

/* user profile section */
.settings h5 {
  font-size: 1.6rem;
  font-weight: 600;
  color: #344054;
}

.settings .profile-details section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
}

.profile-details section ul {
  display: flex;
  align-items: center;
  list-style: none;
  width: 60%;
  margin: 0;
  padding: 0;
}
.profile-details section ul li {
  color: #667085;
  font-family: Inter;
  font-size: 1rem;
  font-weight: 400;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px #1018280d;
  padding: 10px 14px;
  width: 100%;
}

.profile-details section:first-child ul li:first-child {
  margin-right: 20px;
}

.settings .profile-details p,
.settings .form-parent p {
  font-size: 1rem;
  font-weight: 500;
  color: #344054;
  margin: 0;
  margin-bottom: 10px;
}

.settings hr {
  border: 1px solid #eaecf0;
  margin: 20px 0;
}

.settings .form-parent {
  display: flex;
  justify-content: space-between;
  width: 70%;
}

.settings .form-parent form {
  display: block;
  width: 60%;
  padding-bottom: 50px;
}

.settings .form-parent input {
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  color: #101828;
  font-size: 14px;
  font-weight: 400;
  margin: 0 auto 30px 0;
  margin-top: 10px;
  padding: 10px 14px;
  width: 100%;
}

.settings .form-parent input::placeholder {
  color: #101828;
}

.settings .form-parent form button {
  background-color: #6499e9;
  border: 1px solid #6499e9;
  border-radius: 8px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  margin-top: 30px;
  padding: 10px 14px;
  margin-left: auto;
}

/* form section */
.settings .form-parent .input-field-error {
  color: red;
  font-size: 0.9rem;
  margin: -30px 0 20px 0;
}

/* Responsive styling for the settings page */
@media screen and (max-width: 450px) {
  .settings {
    padding: 2rem 0;
  }

  .settings .profile-parent {
    padding: 0 20px;
  }

  /* user profile section */
  .settings h5 {
    margin-bottom: 10px;
  }

  .settings .profile-details section {
    flex-wrap: wrap;
    width: 100%;
  }

  .profile-details section ul,
  .settings .form-parent,
  .settings .form-parent form {
    width: 100%;
  }

  .settings .form-parent {
    flex-wrap: wrap;
  }

  .settings .form-parent input {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 20px;
  }

  .settings .form-parent form button {
    width: 100%;
  }

  /* form section */
  .settings .form-parent .input-field-error {
    font-size: 1rem;
    margin-top: -15px;
  }
}
