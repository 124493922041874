@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600&family=Poppins:wght@600&family=Rubik:wght@300;400&display=swap');

.coding-challenge {
  background-color: #404756;
  color: #fff;
  padding: 1rem 2rem;
  width: 100%;
  padding-bottom: 50px;
}

.language-dropdown {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
}

.header-welcome {
  margin: 0;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-welcome h3 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  margin: 0;
  margin-top: 30px;
  margin-bottom: 5px;
}

.header-welcome h1 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 20px;
}

.header-welcome h3 span {
  color: #ddefff;
}

.leader-button {
  background-color: #fff;
  color: #000;
  padding: 5px 15px;
  border-radius: 3px;
}

.lang-theme-dropdowns {
  align-self: flex-end;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.lang-theme-dropdowns button {
  color: #344054;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  border-radius: 4px;
}

.code-playground,
#coding-question-board {
  border-radius: 3px;
  color: #fff;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  width: 100%;
}

#coding-question-board {
  background-color: #667085;
  border-radius: 8px;
  font-size: 25px;
  margin-bottom: 20px;
  padding: 25px 20px;
}

.button-div {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 15px;
}

.button-div button {
  background: #fff;
  color: #344054;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-top: 20px;
  padding: 8px 15px;
  border: 2px solid #fff;
  border-radius: 4px;
  transform: scale(1);
}

.button-div button:hover {
  cursor: pointer;
  background: rgb(228, 227, 227);
}

.button-div button:active {
  transform: scale(0.95);
  animation: all 250ms ease-in-out linear;
}

.button-div > div {
  display: flex;
}

.button-div > div button {
  background: #fff;
  border: 2px solid #fff;
  border-radius: 4px;
  color: #344054;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding: 8px 15px;
  transform: scale(1);
}

.button-div > div button:nth-child(2) {
  margin-left: 20px;
}

.remove-btn-prev {
  opacity: 0.3;
  pointer-events: none;
  cursor: default;
}
