@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600&family=Poppins:wght@600&family=Rubik:wght@300;400&display=swap');

.modal-parent {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #000000be;
}

.board {
	background: #516beb;
	border-radius: 4px;
	color: #fff;
	padding: 20px;
	position: relative;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 42%;
	height: auto;
}

.board div {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

table {
	width: 100% !important;
}

.board button {
	background: #fff;
	color: #516beb;
	border-radius: 4px;
	border: none;
	font-family: 'Inter', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 1rem;
	padding: 10px 20px;
	margin: 0;
}

.board button:hover {
	background: rgb(228, 227, 227);
}

@media screen and (max-width: 768px) {
	.board {
		width: 100%;
		margin: 0 20px;
		top: 30%;
	}

	.board button {
		font-size: .9rem;
		padding: 7px 15px;
	}
}
