/* custom theme color */
.light-theme {
  /* Define each variable in their light mode */
  --primary-bg: #fff;
  --primary-text: #000;
  --secondary-bg: #f9fbff;

  --header-text: #344563;
  --header-bg: #b8c5f274;
  --video-header-text: #000;
  --scrollview-border: #516beb;

  --course-content-bg: #f9fbff;
  --course-content-border: #e0e0e0;
  --course-content-text: #9e9e9e;
  --course-content-active-text: #7388ef;
  --course-content-active-bg: #f9fbff;
  --course-header-active-border: #516beb;
  --course-content-active-border: #516beb;
}

.dark-theme {
  /* Define each variable in their dark mode */
  --primary-text: #fff;
  --primary-bg: #404756;
  --secondary-bg: #667085;
  --secondary-bg-border: #ddf2ff;

  --header-text: #ddf2ff;
  --header-bg: #404756;
  --video-header-text: #ddf2ff;
  --scrollview-border: #eef4ff;

  --course-content-bg: #404756;
  --course-content-text: #ddefff;
  --course-content-border: #404756;
  --course-content-active-text: #667085;
  --course-content-active-bg: #ddf2ff;
  --course-header-active-border: #eef4ff;
  --course-content-active-border: #667085;
}

/* styling for the video player heading*/
.video-player {
  background-color: var(--primary-bg);
}

.video-player .videoplayer-container {
  display: grid;
  grid-template-columns: 30% 70%;
  grid-gap: 40px;
  align-items: center;
  padding: 47px 2rem;
  height: 90vh;
  width: 100%;
}

.video-player .course-content {
  width: 100%;
  height: 75%;
  align-self: flex-start;
}

.video-player .course-content .course-content-header {
  background: var(--header-bg);
  border-radius: 10px;
  border: 1px solid var(--secondary-bg-border);
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px 0 15px;
}

.video-player .course-content .course-content-header-text,
.video-player .course-content .course-content-header-text-active {
  border-bottom: 3px solid var(--header-bg);
  color: var(--header-text);
  cursor: pointer;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  padding-bottom: 8px;
}

.video-player .course-content .course-content-header-text-active {
  border-bottom: 3px solid var(--course-header-active-border);
}

/* for dropdown videos */
.video-player .dropdown-div,
.video-player .dropdown-div-active {
  background: var(--course-content-bg);
  box-shadow: 0px 3px 14.8459px rgba(79, 94, 122, 0.08);
  border: 1px solid var(--course-content-border);
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 17px 12px;
  width: 100%;
}

.video-player .dropdown-div #font,
.video-player .dropdown-div-active #font {
  cursor: pointer;
}

.video-player .dropdown-div-active {
  background: var(--course-content-active-bg);
  border: 1px solid var(--course-content-active-border);
}

.video-player .dropdown-div p,
.video-name .dropdown-div-active p,
.video-player .dropdown-div-active > p {
  color: var(--course-content-text);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}

.video-player .dropdown-div-active > p {
  border-bottom: 1px solid var(--course-content-active-border);
  color: var(--course-content-active-text);
  padding-bottom: 10px;
  margin-bottom: 15px;
}

/* dropdown video styling ends up here */
.video-player .course-content .video-name-scrollview {
  overflow-y: scroll;
  background: var(--secondary-bg);
  border-top: none;
  border: 1px solid var(--secondary-bg-border);
  border-radius: 0px 0px 10px 10px;
  padding: 10px;
  height: 70vh;
}

.video-player .course-content .video-name,
.video-player .course-content .video-name-active {
  background: var(--course-content-bg);
  box-shadow: 0px 3px 14.8459px rgba(79, 94, 122, 0.08);
  border: 1px solid var(--course-content-border);
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 17px 12px;
  width: 100%;
}

.video-player .course-content .video-name-active {
  background: var(--course-content-active-bg);
  border: 1px solid var(--course-content-active-border);
  border-radius: 4px;
}

.video-player .course-content .video-name p,
.video-player .course-content .video-name-active p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--course-content-text);
  margin: 0;
}

.video-player .course-content .video-name-active p {
  color: var(--course-content-active-text);
}

.video-player .course-content .video-name-scrollview::-webkit-scrollbar {
  width: 3px;
}

.video-player .course-content .video-name-scrollview::-webkit-scrollbar-track {
  background: var(--scrollview-border);
  border: 2px solid var(--scrollview-border);
  margin: 10px;
}

.video-player .course-content .video-name-scrollview::-webkit-scrollbar-thumb {
  outline: 4px solid #516beb;
  background-color: #516beb;
  border-radius: 13px;
}

.video-name-resource {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--course-content-text);
  padding: 10px 0;
}

.video-name-resource a {
  background: var(--scrollview-border);
  border: 1px solid var(--scrollview-border);
  color: var(--course-content-bg);
  border-radius: 4px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 0;
  padding: 20px;
}

.video-name-instructor {
  display: flex;
  align-items: center;
}

.video-name-instructor img {
  width: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.video-name-instructor h6 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: var(--video-header-text);
  margin: 0;
}

.video-name-instructor p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: rgba(81, 107, 235, 0.7);
}

/* video-player-aside styling */
.video-player .videoplayer-aside {
  width: 95%;
  height: 65%;
  align-self: flex-start;
}

.video-player .videoplayer-aside h5 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 30.6858px;
  line-height: 37px;
  color: var(--video-header-text);
  margin-bottom: 20px;
}

.videoplayer-aside-video {
  width: 100%;
  /* height: 70% !important; */
  border: 6px solid #344563;
  border-radius: 10px;
}

.video-player .theme-toggle {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.video-player .theme-toggle span {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  color: var(--video-header-text);
  margin-right: 10px;
}

/* course description styling */
.video-player .video-course-description {
  padding: 40px 2rem;
}

.video-player .video-course-description h6 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: var(--video-header-text);
}

.video-player .video-course-description hr {
  background-color: #516beb;
  border: 2px solid #516beb;
  margin: 10px 0 30px 0;
}

.video-player .video-course-description p,
.video-player .video-course-description li {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  color: var(--video-header-text);
}

/* Responsive styling */
@media screen and (max-width: 450px) {
  /* styling for the video player heading*/

  .video-player .videoplayer-container {
    grid-template-columns: 1fr;
    grid-gap: 10px;
    padding: 40px 20px;
    min-height: 100vh;
  }

  .video-player .course-content {
    grid-area: 2;
  }

  .video-player .course-content .course-content-header-text,
  .video-player .course-content .course-content-header-text-active {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }
  /* dropdown video styling ends up here */

  .video-player .course-content .video-name-scrollview {
    height: 60vh;
  }

  /* video-player-aside styling */
  .video-player .videoplayer-aside {
    width: 100%;
  }

  .video-player .videoplayer-aside h5 {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }

  .video-player .theme-toggle span {
    font-size: 14px;
  }

  /* course description styling */
  .video-player .video-course-description {
    padding: 40px 20px;
    margin-top: 70px;
  }

  .video-player .video-course-description h6 {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }

  .video-player .video-course-description p,
  .video-player .video-course-description li {
    font-size: 14px;
  }
}
