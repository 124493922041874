@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600&family=Poppins:wght@600&display=swap');

:root {
  --bg-blue: #6499e9;
  --border-blue: 3px solid #6499e9;
  --quiz-text-color: #424242;
}

.quiz-aside h3,
.quiz-score h3 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  color: #6499e9;
  margin-bottom: 25px;
}

.quiz-body .quiz-header-caption {
  background: #2f327d;
  color: #fff;
  text-align: center;
  padding: 20px 0;
}

.quiz-header-caption h2 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 2.3rem;
}

.quiz {
  display: grid;
  grid-template-columns: 1fr;
  padding: 50px 6rem;
}

.quiz-aside {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.13);
  border-radius: 8px;
  padding: 40px 20px;
  width: 100%;
}

.quiz-aside ul li input {
  width: 20px;
  height: 20px;
  margin: 0;
  margin-right: 10px;
}

.quiz-score {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.13);
  border-radius: 8px;
  padding: 40px 50px;
  width: 100%;
}

.quiz-score-chart {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.quiz-score-chart h2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
}

.quiz-score-chart p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  margin-top: 5px;
  color: rgb(70, 70, 70);
}

.quiz-score-chart .next-module-btn {
  border: 3px solid var(--bg-blue);
  color: var(--bg-blue);
  border-radius: 4px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  padding: 10px 0;
  cursor: pointer;
  padding: 10px 20px;
  margin-top: 50px;
}

.quiz-pass-fail {
  text-align: right;
}

.quiz-pass-fail h4,
.quiz-fail h4 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  color: #516beb;
}

.quiz-pass-fail p,
.quiz-fail p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  margin: 0;
  color: #000000;
}

.quiz-pass-fail div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 150px;
}

.quiz-fail div {
  margin-top: 150px;
}

.quiz-pass-fail div button,
.quiz-fail div button {
  border: 3px solid var(--bg-blue);
  background-color: transparent;
  color: var(--bg-blue);
  border-radius: 4px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  padding: 10px 0;
  cursor: pointer;
  padding: 10px 20px;
}

.quiz-pass-fail div button:nth-child(2) {
  background: var(--bg-blue);
  color: #fff;
  margin-left: 20px;
}

@media screen and (min-width: 600px) {
  .quiz {
    grid-template-columns: 25% 70%;
    grid-gap: 0 3rem;
  }
}

@media screen and (max-width: 600px) {
  .quiz-aside h3,
  .quiz-score h3 {
    font-size: 1.3rem;
    margin-bottom: 20px;
  }

  .quiz-header-caption h1 {
    font-size: 1.3rem;
  }

  .quiz {
    padding: 30px 1rem;
  }

  .quiz-aside {
    display: none;
  }

  .quiz-score {
    padding: 40px 50px;
  }

  .quiz-score h3 {
    display: none;
  }

  .quiz-score-chart {
    display: flex;
    justify-content: center;
  }

  .quiz-pass-fail,
  .quiz-fail {
    text-align: center;
    margin-top: 30px;
  }

  .quiz-pass-fail h4 {
    font-size: 2.2rem;
  }

  .quiz-pass-fail div,
  .quiz-fail div {
    margin-top: 50px;
    justify-content: center;
  }

  .quiz-pass-fail div button,
  .quiz-fail div button {
    font-size: 0.8rem;
    padding: 8px;
  }
}
