@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Poppins:wght@600&display=swap');

:root {
  --bg-blue: #6499e9;
  --border-blue: 3px solid #6499e9;
  --quiz-text-color: #424242;
}

.quiz-section h3 {
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 18px;
  color: #516beb;
  margin-bottom: 20px;
}

.quiz-body .quiz-header-caption {
  background: #436898;
  color: #fff;
}

.quiz-header-caption h1 {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 24px;
  margin: 0;
}

.quiz-body #center-header {
  font-family: Inter;
  background-color: #6499e9;
  color: #f7fafd;
  width: fit-content;
  border-radius: 10px;
  padding: 5px 20px;
  font-size: 14px;
  margin: 20px auto;
}

.quiz {
  display: flex;
  width: 65%;
  margin: auto;
}

.quiz-section {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.13);
  border-radius: 8px;
  padding: 40px 50px;
  width: 100%;
  margin-bottom: 30px;
}

.quiz-section form p {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 1.2rem;
  color: #000000;
}

.quiz-section form label {
  display: flex;
  align-items: center;
  border: var(--border-blue);
  border-radius: 8px;
  color: var(--quiz-text-color);
  font-family: 'Inter';
  font-weight: 400;
  font-size: 0.95rem;
  margin-bottom: 20px;
  padding: 10px 20px;
  cursor: pointer;
}

.quiz-section form label input {
  border: 1px solid #b1b1b1;
  height: 20px;
  margin: 0;
  margin-right: 10px;
  width: 20px;
}

.quiz-section div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 30px;
}

.quiz-section div button {
  border: 3px solid var(--bg-blue);
  background-color: transparent;
  color: var(--bg-blue);
  border-radius: 4px;
  font-family: 'Inter';
  font-weight: 700;
  font-size: 18px;
  padding: 10px 0;
  cursor: pointer;
  width: 150px;
}

.quiz-section div button:nth-child(2) {
  background: var(--bg-blue);
  color: #fff;
  margin-left: 20px;
}

.quiz-section .home-button {
  font-family: 'Poppins';
  padding: 15px 20px;
  border: var(--quiz-text-color);
  margin-top: 40px;
  background-color: var(--bg-blue);
}

@media screen and (max-width: 768px) {
  .quiz-section {
    padding: 20px 30px;
  }

  .quiz-section form p {
    font-size: 1.2rem;
  }

  .quiz-section form label {
    padding: 10px;
    font-size: 0.9rem;
  }

  .quiz-section form label input {
    height: 15px;
    width: 15px;
  }

  .quiz-section div {
    justify-content: center;
    margin-top: 50px;
  }

  .quiz-section div button {
    font-size: 1rem;
  }
}
