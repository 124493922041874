.course-description {
  background-image: linear-gradient(
    350deg,
    hsl(0deg 0% 100%) 13%,
    hsl(204deg 100% 99%) 39%,
    hsl(203deg 100% 99%) 46%,
    hsl(203deg 100% 98%) 49%,
    hsl(203deg 100% 98%) 50%,
    hsl(203deg 100% 97%) 50%,
    hsl(203deg 100% 97%) 50%,
    hsl(203deg 100% 96%) 50%,
    hsl(203deg 100% 96%) 50%,
    hsl(203deg 100% 95%) 50%,
    hsl(203deg 100% 95%) 51%,
    hsl(203deg 100% 94%) 54%,
    hsl(203deg 100% 94%) 61%,
    hsl(203deg 100% 93%) 87%
  );
  color: #000;
  font-family: 'Inter', sans-serif;
  display: grid;
  grid-template-columns: 35% 65%;
  justify-content: center;
  padding: 50px 100px 100px 100px;
}

.course-description-thumbnail {
  box-shadow: 0px 3px 16px -4px #10182814;
  border-radius: 10px;
  background-color: #fff;
  padding-bottom: 30px;
  height: 55vh;
  width: 100%;
}

.course-description-thumbnail .thumbnail-player {
  /* on hover, this styles the play button for the video */
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 85%;
  width: 100%;
  position: relative;
}

.course-description-thumbnail .thumbnail-player img {
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.course-description-thumbnail h1 {
  color: #667085;
  font-size: 22px;
  font-weight: 600;
  margin: 10px 15px;
}

.thumbnail-player:hover .course-desc-play-icon-body {
  display: flex;
  animation: zoom-in 250ms ease-in-out alternate;
}

@keyframes zoom-in {
  0% {
    transform: scale(0.6, 0.6);
  }
  50% {
    transform: scale(0.8, 0.8);
  }
  100% {
    transform: scale(1, 1);
  }
}

.course-desc-react-player {
  position: absolute;
  padding: 5px;
  border: 5px solid #032073;
}

.course-desc-play-icon-body {
  display: none;
  position: absolute;
  background: #6499e9;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  top: 43%;
  left: 43%;
  padding-left: 5px;
  cursor: pointer;
}

.course-desc-play-icon {
  font-size: 50px;
  fill: #fff;
}

.course-description-content {
  padding: 0 10% 0 3%;
}

.course-description-content h2 {
  font-weight: 700;
  font-size: 26px;
}

.course-description-content p,
.course-description-content h3,
.course-description-content ol li {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
}

.course-description-content p:nth-last-child(2) {
  margin: 0;
}

.course-description-content h4 {
  font-weight: 600;
  font-size: 14px;
}

.go-to-course-btn .progress,
.go-to-course-btn .progress-complete {
  display: flex;
  align-items: center;
  background-color: transparent;
  font-weight: 400;
  font-size: 14px;
  height: fit-content;
}

.go-to-course-btn .progress {
  color: #f48c06;
}

.go-to-course-btn .progress-complete {
  color: #41be90;
}

.go-to-course-btn button {
  background: #6499e9;
  border-radius: 8px;
  border: 1px solid #6499e9;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  width: fit-content;
  padding: 10px 20px;
}

/* Responsive implementation */
@media screen and (max-width: 450px) {
  .course-description {
    grid-template-columns: 1fr;
    padding: 40px 20px;
  }

  .course-description-thumbnail {
    padding-bottom: 20px;
    height: 40vh;
  }

  .course-description-thumbnail h1 {
    font-size: 18px;
  }

  .course-desc-play-icon-body {
    height: 50px;
    width: 50px;
  }

  .course-desc-play-icon {
    font-size: 40px;
  }

  .course-description-content {
    padding: 20px 0;
  }

  .course-description-content h2 {
    font-size: 18px;
  }

  .course-description-content p,
  .course-description-content h3,
  .course-description-content ol li {
    font-size: 14px;
    line-height: 28px;
  }
}
