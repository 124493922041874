@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600&family=Poppins:wght@600&family=Rubik:wght@300;400&display=swap');

.light-theme {
  --text-color: #ddefff;
  --bg-color: #6499e9;
}

.dark-theme {
  --text-color: #ddf2ff;
  --bg-color: #667085;
}

.desktop-navbar {
  display: flex;
  background-color: var(--bg-color);
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  height: 72px;
}

.icon-logo,
.desktop-navbar ul {
  display: flex;
  align-items: center;
  color: var(--text-color);
  margin: 0;
  padding: 0;
}

.icon-logo {
  width: 200px;
}

.icon-logo img {
  width: 100%;
  /* margin-right: 10px; */
}

.desktop-navbar ul {
  list-style: none;
}

.desktop-navbar ul li {
  color: var(--text-color);
  font-family: 'Inter';
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  padding: 5px 20px;
}

.desktop-navbar ul li {
  cursor: pointer !important;
}

.navbar-is-active {
  background-color: var(--text-color);
  border: 2px solid var(--text-color);
  border-radius: 6px;
}

.navbar-is-active .mobile-nav-list {
  color: #6499e9;
}

.navlinks-icon {
  font-size: 20px;
}

.desktop-navbar ul li:nth-child(1) {
  cursor: default;
}

.mobile-navbar {
  display: none;
}

@media screen and (max-width: 768px) {
  .desktop-navbar {
    display: none;
  }

  .mobile-navbar {
    display: block;
    position: relative;
    font-family: 'Inter', sans-serif;
  }

  .mobile-nav-body {
    background-color: var(--bg-color);
    width: 100%;
    max-width: 100%;
    height: 8vh;
    padding: 0 15px;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .mobile-nav-body .icon-logo {
    border: none !important;
  }

  .mobile-nav-body .icon-logo img {
    width: 130px;
  }

  .mobile-nav-body div {
    display: flex;
    align-items: center;
  }

  .mobile-nav-body li {
    border: 2px solid var(--text-color);
    border-radius: 6px;
    color: var(--text-color);
    font-weight: 400;
    font-size: 14px;
    list-style: none;
    line-height: 22px;
    padding: 3px 7px;
  }

  /* hamburger menu styling */
  .mobile-hamburger-btn {
    width: 28px;
    height: 3px;
    background: var(--text-color);
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
    margin-left: 20px;
  }

  .mobile-hamburger-btn::after,
  .mobile-hamburger-btn::before {
    content: ' ';
    position: absolute;
    width: 28px;
    height: 3px;
    background: var(--text-color);
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
  }

  .mobile-hamburger-btn::before {
    transform: translateY(-10px);
  }

  .mobile-hamburger-btn::after {
    transform: translateY(10px);
  }

  /* mobile-hamburger animation */
  .mobile-hamburger-btn.close {
    transform: translateX(-50px);
    background: transparent;
  }

  .mobile-hamburger-btn.close::before {
    transform: rotate(45deg) translate(35px, -35px);
  }

  .mobile-hamburger-btn.close::after {
    transform: rotate(-45deg) translate(35px, 35px);
  }

  /* nav links style on mobile view */
  .mobile-nav-links {
    position: fixed;
    top: 8vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: var(--bg-color);
    min-height: 60vh;
    width: 100%;
    z-index: 1;
    clip-path: inset(0% 0% 100% 0%);
    -webkit-clip-path: inset(0% 0% 100% 0%);
    padding-top: 7vh;
    transition: all 0.5s ease-out;
    pointer-events: none;
    list-style: none;
  }

  .mobile-nav-list,
  .mobile-nav-links {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #f9fbff;
    border-bottom: 2px solid #f9fbff;
    width: fit-content;
  }

  .mobile-nav-links {
    width: 100%;
  }

  .mobile-nav-links.open {
    width: 100%;
    clip-path: inset(0% 0% 0% 0%);
    -webkit-clip-path: inset(0% 0% 0% 0%);
    pointer-events: all;
  }
}
