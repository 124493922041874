@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

.confirm-email-parent {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100%;
}

.confirm-email {
	color: #000;
	font-family: 'Inter', sans-serif;
	font-style: normal;
	border: 3px solid #516beb;
	border-radius: 33px;
	padding: 2rem;
	width: 45%;
}

.confirm-email-header {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-bottom: 3px solid #516beb;
	padding-bottom: 1rem;
}

.confirm-email-header img {
	height: 50px;
	width: 50px;
	margin: auto;
	margin-bottom: 20px;
}

.confirm-email-header h1 {
	font-size: 34px;
	font-weight: 700;
	text-align: center;
}

.confirm-email-body {
	padding-top: 2rem;
}

.confirm-email-body p {
	font-weight: 500;
	font-size: 20px;
}

.confirm-email-body a {
	color: #516beb;
	cursor: pointer;
	font-weight: 500;
	text-decoration: none;
}

.confirm-email-button {
	color: #516beb;
	cursor: pointer;
	text-decoration: none;
    padding: 2px 8px;
    margin: 2px;
}

.confirm-email-button:hover {
    border: 2px solid #516beb;
    padding: 1px 6px;
    border-radius: 10px;
	/* padding: 2px; */
}

/*  mobile responsiveness styling */
@media screen and (max-width: 768px) {
	.confirm-email {
		width: 90%;
		max-width: 90%;
		padding: 1rem;
	}

	.confirm-email-header {
		padding-bottom: 1rem;
	}

	.confirm-email-header img {
		height: 30px;
		width: 30px;
	}

	.confirm-email-header h1 {
		font-size: 24px;
	}

	.confirm-email-body {
		padding-top: 1rem;
	}

	.confirm-email-body p {
		font-size: 14px;
	}
}
