@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600&family=Poppins:wght@600&family=Rubik:wght@300;400&display=swap');

.modal-parent {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #000000be;
}

.modal-success-body {
	background: green;
	border-radius: 4px;
	color: #fff;
	display: flex;
	justify-content: center;
	padding: 20px 10px;
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 28%;
}

#iconFont {
	margin-right: 10px;
	font-size: 2rem;
}

.modal-success-body h1 {
	font-family: 'Inter', sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 2rem;
	margin: 0;
}

.modal-success-body p {
	font-family: 'Inter', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 1rem;
}

.modal-success-body button {
	float: right;
	background: #fff;
	border-radius: 4px;
	border: none;
	font-family: 'Inter', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 1.4rem;
	margin-top: 10px;
	padding: 10px 20px;
}

.modal-success-body button:hover {
	background: rgb(228, 227, 227);
}
