.subscription-container {
  display: grid;
  grid-template-columns: 40% 60%;
  align-items: center;
}

.subscription-image-section,
.subscription-image-section img {
  width: 100%;
  height: 100%;
  height: 90vh;
}

.subscription-image-section img {
  object-fit: cover;
  object-position: top;
}

.subscription-text-section {
  display: block;
  color: #000000;
  font-family: 'Inter', sans-serif;
  padding: 0 50px;
}

.subscription-text-section h1 {
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 30px;
}

.subscription-text-section h3,
.subscription-text-section h4 {
  font-size: 18px;
  font-weight: 600;
  color: #334155;
}

.subscription-text-section h4 {
  color: #6499e9;
  margin-bottom: 40px;
}

.subscription-text-section p {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
}

.subscription-text-section a {
  font-size: 14px;
  font-weight: 600;
  background-color: #6499e9;
  border: 1px solid #6499e9;
  border-radius: 8px;
  color: #fff;
  width: fit-content;
  padding: 10px 16px;
}

/* Responsive styling for mobile screen */
@media screen and (max-width: 450px) {
  .subscription-container {
    grid-template-columns: 100%;
  }

  .subscription-image-section,
  .subscription-image-section img,
  .subscription-text-section p {
    display: none;
  }

  .subscription-text-section {
    height: 90vh;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .subscription-text-section h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .subscription-text-section h4 {
    margin-bottom: 30px;
  }
}
